import React from "react";

const Copyright = () => {
  return (
    <>
      <p className="text-center mb-0">
        Mars no es una compañía de seguros ni aseguradora en ningún sentido
        legal o real. &copy;2022 Mars o Afiliados. Las marcas registradas de
        terceros son propiedad de sus respectivos dueños.
      </p>
    </>
  );
};

export default Copyright;
